#root {
    padding: 50px;
    width: 90%;
    margin: auto;
}

.MuiAccordion-root {
    margin-top: 20px;
}

h2 {
    margin-bottom: 20px;
}

/*******************/
/* Panel Header Styles */

.panel-header {
    border: none;
    border-radius: 2px;
    box-shadow: none;
    min-height: 56px;
    font-size: 16px;
    background-color: white;
    transition: background-color 0.2s ease-in-out !important;
}

.panel-header:not(.Mui-expanded):hover {
    background-color: rgb(229, 229, 229);
}

.Mui-expanded.MuiButtonBase-root.panel-header {
    background-color: #08a4ee;
    padding: 0 15px;
    margin: 0;
    border-radius: 4px 4px 0 0;
}

.panel-header .Mui-expanded > .MuiTypography-root {
    color: white;
    font-size: 18px;
    margin: 0;
}

#panel3d-header {
    display: none;
    /* this is to hide the locations table */
}

/*******************/

/*******************/
/* Table Styles */

#panel2d-content {
    padding: 25px;
    background-color: white;
    height: 70vh;
    justify-content: center;
    display: flex;
}

.dataTable {
    position: relative;
    min-height: 40vh;
    height: 60vh;
    width: 84vw;
}

.ag-row-even:not(.ag-row-selected) {
    background-color: #D3E7FF3B !important;
}

.ag-row-odd, .ag-row-even {
    transition: background-color 0.1s linear !important;
}

.ag-row-odd:not(.ag-row-selected):hover, .ag-row-even:not(.ag-row-selected):hover {
    background-color: #dde4ec !important;
}

.dataTable, .dataTable > div {
    position: relative;
    flex: 1;
}

.ag-sort-order {
    display: none;
}

/*******************/

#user-status-toggle, #user-status-toggle:hover {
    background-color: transparent;
}

#to-hide {
    display: none;
}

#to-show {
    display: inline;
}

.location-select-dropdown {
    cursor: pointer;
    width: auto;
    background-color: white;
    box-shadow: 0 2px 0 white;
    border-radius: 2px;
}

.actionButton {
    margin-bottom: 5px;
}

.location-option {
     transition: all 250ms cubic-bezier(.4,.25,.3,1);
     display: block;
     width: auto;
     height: 0;
     font-size: small;
     line-height: 40px;
     overflow: hidden;
     color: #3e3e3e;
     background-color: #fff;
     cursor: pointer;
     padding-left: 10px;
}
