/**
* WARNING WARNING WARNING
*
* ...jk no warning. but srsly, TODO:
* Make this styled-component stuff
* and get off of css modules like the
* rest of the cool kids.
*/

.SA_Parent {
  z-index: 1;
  position: relative;
  display: flex;
  flex: 1;
  color: #333333;
  height: calc(100% - 70px);
}

.DateRangePickerConntainer {
  position: relative;
}

.SA_Button {
  /*Reset*/
  cursor: pointer;
  /* margin: 0.5rem 1rem 0.5rem 1rem; */
  padding: 0.5rem 1rem 0.5rem 1rem;
  pointer-events: auto;

  font-size: 0.9rem;
  font-weight: 700;
  color: white;
  background-color: #0075c9;
  border-width: 0px;
  border-radius: 6px;
  cursor: pointer;
}

.SA_Button__inverted {
  color: #0075c9;
  border: 1px solid #0075c9;
  background-color: white;
}

.SA_Content {
  width: 100%;
  background: #00000012;
  overflow: scroll;
}

.SA_Page {
  position: relative;
  height: 100%;
  width: 100%;

  transition: all 1s ease;
  /*Fill container*/
  display: flex;
  flex: 1;

  overflow: hidden;

  /*Dev visibility*/
}

.SA_Page__isLoading {
  /*Center Content*/
  justify-content: center;
  align-items: center;

  /*Dev visibility*/
}

.SA_Page__hasReviews {
  background-color: white;

  /*Dev visibility*/
}

.SA_Page__isModal {
}

.SA_Page__isModal::before {
}

.SA_ReviewsTable {
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 3rem;
  padding-bottom: 72px;

  transition: all 500ms ease;
  top: 10vh;
  opacity: 0;
  /*Dev visibility*/
}

.SA_ReviewsTable__slideIn {
  position: relative;

  opacity: 100;
  top: 0vh;
}

.SA_ReviewsTable__Header {
  z-index: 101;
  font-size: 1.7rem;
  font-weight: 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #00000033;
}

.SA_ReviewsTable__Body {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;

  /*Dev visibility*/
}

.SA_PatientsTable__Header {
  /* padding-top: 2rem; */
  /* font-weight: 600; */
  /* position: sticky; */
  /* top: 0; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* box-shadow: 3px 3px 3px black; */
  /* background: white; */
  /* z-index: 100; */
  /* font-size: 1.8rem; */
  /* align-items: 'center'; */
}

.SA_PatientsTable__Header___patientName {
  padding-bottom: 0.3rem;
  margin-top: auto;
}

.SA_PatientsTable__Header___text {
}

.dateRangePicker {
}

.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid light-red;
}

.CalendarDay__selected {
  background: dark-red;
  color: white;
}

.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}

.ARS_Header_search_filter {
  display: flex;
  flex: 1.2;
  /* background-color: blue; */
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;
}

.SA_ShiftsTable__Header {
  z-index: 2;
  position: sticky;
  top: 5.2rem;
}

.SA_PatientsTable__Body {
  pointer-events: none;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 1rem;

  /*Dev visibility*/
  border-bottom: 0.5px solid #00000077;
}

.SA_PatientsTable__Footer {
  display: flex;
  flex-direction: row;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #00000077;
}

.SA_PatientsTable__Footer > div {
  padding-left: 1.1rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  color: #00000077;
}

.SA_ShiftsTable__Header {
  display: flex;
  flex-direction: row;
  color: white;
  font-weight: 600;
  padding-left: 1rem;
  background-color: rgb(90, 93, 133);

  /*Dev visibility*/
}

.SA_ShiftsTable__Header > div {
  flex: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.SA_Shift {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #00000077;
}

.SA_Shift > div {
  align-items: center;
  padding-left: 1.1rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  color: black;
}

@media (max-width: 1000px) {
  .SA_Shift > div,
  .SA_ShiftsTable__Header,
  .SA_PatientsTable__Footer > div {
    padding-left: 0;
  }

  .SA_ReviewsTable__Header {
    font-size: 1.5rem;
  }

  .SA_ShiftsTable__Header {
    display: none;
  }

  .SA_ShiftsTable__Body > div {
    margin: 0;
    padding: 2rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: left;
    font-size: 1.5rem;
  }

  .SA_ShiftsTable__Body > div > div {
    display: flex;
    flex-direction: column;
  }

  .SA_ShiftsTable__Body > div > div::before {
    background-color: rgb(90, 93, 133);
    color: white;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    padding: 0.3rem;
    font-weight: 600;
    font-size: 1.1rem;
    text-align: center;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #00000077;
  }

  .SA_Shift {
    display: flex;
    box-shadow: 3px 3px 3px black;
    border: 1rem solid rgb(90, 93, 133);
  }

  .SA_Shift > div {
    justify-content: flex-end;
  }

  .SA_ShiftsTable__Body > div > div:nth-child(1) {
  }

  .SA_ShiftsTable__Body > div > div:nth-child(1)::before {
    content: 'DATE';
  }

  .SA_ShiftsTable__Body > div > div:nth-child(2) {
  }

  .SA_ShiftsTable__Body > div > div:nth-child(2)::before {
    content: 'HRS';
  }

  .SA_ShiftsTable__Body > div > div:nth-child(3) {
  }

  .SA_ShiftsTable__Body > div > div:nth-child(3)::before {
    content: 'LOGGED TIME';
  }

  .SA_ShiftsTable__Body > div > div:nth-child(4) {
    align-items: center;
    justify-content: center;
  }

  .SA_ShiftsTable__Body > div > div:nth-child(4)::before {
    display: block;
    content: 'ALERTS';
  }

  .SA_ShiftsTable__Body > div > div:nth-child(5) {
    align-items: center;
    justify-content: center;
  }

  .SA_ShiftsTable__Body > div > div:nth-child(5)::before {
    content: 'NOTES';
  }

  .SA_ShiftsTable__Body > div > div:nth-child(6) {
  }

  .icons {
    cursor: pointer;
    justify-content: center;
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
}

.PhoneSpan {
  color: #0075c9;
  padding-left: 1rem;
  font-size: 1rem;
  font-weight: 0;
}

.tooltip {
  text-align: center;
  font-size: 0.7rem;
  width: 10rem;
}

.tooltip_header {
  font-size: 1rem;
  font-weight: 900;
  padding-bottom: 0.5rem;
}

.tooltip_type {
  margin: 0.1rem;
  border-radius: 5px;
  border: 1px solid black;
}

.icons {
  pointer-events: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1rem;
}

.SA_Parent {
  z-index: 1;
  position: relative;
  display: flex;
  flex: 1;
}

.SA_ShiftModal {
  z-index: 200;
  transition: all 1s ease;
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: #000000dd;
  color: white;
}

.SA_ShiftModal__isOpen {
  opacity: 1;
}

.SA_ShiftModal__isClosed {
  pointer-events: none;
  opacity: 0;
}

.SA_ShiftDetails {
  transition: all 1s ease;
  position: absolute;
  right: 0px;
  width: 33vw;
  min-width: 500px;
  height: 100%;
  background: white;
  color: black;
}

.SA_ShiftDetails__isOpen {
  opacity: 1;
  transform: scaleX(1);
}

.SA_ShiftDetails__isClosed {
  opacity: 0;
  transform: scaleX(0);
}

.enable_tooltip {
  position: relative;
  display: inline-block;
  pointer-events: auto;
}

.tooltip_icon {
  cursor: help;
  z-index: 1;
}

.settings {
  background: white;
  color: black;
}
