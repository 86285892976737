.status {
    font-weight: bold
}

.status.pending {
    color: grey
}

.status.approved {
    color: green
}

.status.rejected {
    color: red
}

.status.review {
    color: blue
}

.cell.warning {
    box-shadow: 0 0 7px yellow
}
