.SP {
  position: relative;
  overflow-y: auto;
  max-width: 40vw;
  min-width: 600px;
  padding-bottom: 6px;

  /*Dev visibility*/
  font-size: 1rem;
  background: white;
  color: black;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.SP_Header__Main {
  padding: 1rem;
  background: white;
  z-index: 2;
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
}

.SP_Body {
  /* pointer-events: none; not sure why this is in here */
  display: flex;
  flex-direction: column;
  flex: 1;
}

.SP_Body__GeneralInformation {
}

.SP_Body__Alerts {
}

.SP_Body__Activities {
}
.SP_Body__ClockInNotes {
}
.SP_Body__ClockOutNotes {
}

.SP_Footer {
  margin-top: 24px;
  font-size: 1.5rem;
}

.GeneralInformation {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
}

.GeneralInformation_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.GeneralInformation_clockInOutCell {
  flex: 1;
}

.GeneralInformation_row__label {
  flex: 1;
}

.GeneralInformation_row__content {
  display: flex;
  flex: 2;
  flex-direction: row;
  font-weight: 700;
}

.GeneralInformation_row__content3 {
  display: flex;
  flex: 3;
  flex-direction: row;
  font-weight: 700;
}

.GeneralInformation_row__stretch {
  display: flex;
  flex: 3;
  flex-direction: row;
}

.GeneralInformation_row__content___edit {
  display: flex;
  flex: 2;
  flex-direction: row;
  font-weight: 700;
  border: 1px solid black;
}



.GeneralInformation_row__actions {
  justify-content: flex-end;
  display: flex;
  flex: 1;
  flex-direction: row;
}

.GeneralInformation_row__correction {
  flex: 3;
}

.Alerts_row {
  display: flex;
  flex-direction: row;
}

.SS_Signature {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SS_Signature__image {
  max-width: 100%;
  max-height: auto;
  flex: 1;
}

.SS_Signature__bumper {
  flex: 2.8;
}

.SS_Signature__cell {
  flex: 1;
}

.SS_Signature__cell___overflow {
  overflow: visible;
}

.Activities {
}

.closeButton {
  cursor: pointer;
  background: inherit;
  color: inherit;
  border: none;
}

.linkButton {
  cursor: pointer;
  background: inherit;
  color: #0075c9;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 700;
  border: 0;
  pointer-events: auto;
}

.button {
  cursor: pointer;
  margin: 0.5rem 1rem 0.5rem 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  pointer-events: auto;

  font-size: 0.9rem;
  font-weight: 700;
  color: white;
  background-color: #0075c9;
  border-width: 0px;
  border-radius: 6px;
  cursor: pointer;
}

.SP_Header__closeShiftDetails {
  font-size: 0.8rem;
  font-weight: 700;
}

.SP_Header {
  font-size: 1.5rem;
}

.iconText {
  padding-left: 1rem;
  white-space: pre-line;
}

.Activities_Section__Header {
  padding: 1.5rem 0 0.5rem 0;
  font-size: 0.8rem;
}

.Activities_Section__Control {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
}

.Activites_Section__Control___name {
  padding-left: 1rem;
  white-space: pre-line;
}

.Activities_Section__Control___value {
  font-weight: 400;
}

.clockNote {
  font-weight: 700;
}

.smallItalicIconText {
  font-size: 0.8rem;
  font-style: italic;
}

.iconTextRow {
  display: flex;
  flex-direction: row;
}

.datePicker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding: 0.3rem;
  border: 1px solid #00000077;
  border-radius: 5px;
  cursor: pointer;
}

.editGroup {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.editTitleContainer {
  display: flex;
  flex: 1;
}

.editTitle {
  color: #333;
}

.editData {
  font-weight: 600;
  color: #333;
}

.datePicker__disabled {
  cursor: not-allowed;
  color: #919191;
  background-color: rgba(219, 219, 219);
}

.warnHours {
  color: #e51313;
  padding-right: 1em;
  font-size: 1.5em;
}

.warnHoursOff {
  color: black;
}
