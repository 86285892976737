#root {
    padding: 50px;
    width: 90%;
    margin: auto;
}
/*******************/
/* Tab Styles */

.tabContainer {
    background-color: white;
    margin-right: 50px;
    position: relative;
    width: 100%;
    min-height: 600px;
    height: auto;
}

.MuiTabs-root {
    background-color: #08a4ee;
}

.tabPanel {
    margin: 10px 20px;
}
/*******************/

/*******************/
/* Table Styles */

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 15px auto;
    min-height: 600px;
    height: 70vh;
}
.MuiDataGrid-row:nth-child(even) {
    background-color: #D3E7FF3B;
}
.shiftTables, .shiftTables > div {
    position: relative;
    flex: 1;
}

.tableActions {
    display: flex;
    position: relative;
}

/*******************/

/*******************/
/* Modal Styles */

.selectionModal {
    width: 250px;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 25px 15px 15px;
}

.editModalSections {
    padding-bottom: 10px;
    border-bottom: lightgrey 1px solid;
    font-size: 16px;
    margin: 20px;
    width: 500px;
}

/*******************/

/*******************/
/* Input Styles */

.inputSection {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.inputSection input {
    margin: 10px;
}

.inputTextBox {
    border-radius: 3px;
    margin: 5px 10px 10px 0;
    box-shadow: transparent;
    border: 1px solid lightgray;
    padding: 5px;
    font-size: 20px;
    justify-content: flex-start;
    flex: 3;
}

.dateRangeSearchPrompt, .editModalSections {
    margin: 5px 10px 10px 0;
    padding: 5px;
    justify-content: flex-start;
}

.dateRangeSearchPrompt {
    font-size: 20px;
}

.dateRangeSearch {
    justify-content: flex-end;
    flex: 2;
}

.wholeTableButton {
    font-size: 16px;
    margin: 5px;
    justify-content: flex-start;
}

.actionButton {
    background-color: #08A4EE;
    border-radius: 3px;
    border: none;
    box-shadow: 0 1px 4px lightgray;
    padding: 4px 6px;
    margin-right: 2px;
    color: white;
    font-family: 'Roboto',sans-serif;
    transition: all 0.3s ease-in-out;
    line-height: 18px;
}

.actionButton:hover {
    background-color: #3f95be;
}

.actionButton-l {
    width: 75%;
    height: 20%;
    font-size: 20px;
    margin-top: 20px;
    position: relative;
    left: 12%;
}

.actionButton:disabled {
    background-color: #919191;
}

/*******************/

/*******************/
/* Tooltip Styles */

.tooltipClass {
    position: relative;
    z-index: 99;
}

.tooltipClass .tooltipText {
    width: 10px;
    background-color: rgba(85, 189, 236, 0.44);
    opacity: 0;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 99;
}

.tooltipClass:hover .tooltipText {
    width: 120px;
    opacity: 1;
    animation: slideOut ease-in-out 0.5s;
    z-index: 99;
}

.tooltipClass .tooltipText::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(85, 189, 236, 0.44) transparent;
    z-index: 99;
}

/*******************/

/*******************/
/* Animations */

@keyframes slideOut {
    0% { width: 10px; opacity: 0;}
    100% { width: 120px; opacity: 1;}
}
