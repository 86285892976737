.editGroup {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.editTitleContainer {
  display: flex;
  flex: 1;
}

.editTitle {
  color: #333;
}

.editData {
  font-weight: 600;
  color: #333;
}
