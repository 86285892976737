
.amelia-mobile-click {
    cursor: pointer;
}
.chat-overlay {
    position: fixed;
    width: 376px;
    height: 500px;
    bottom: 24px;
    right: 24px;
    z-index: 90;
}

.chat-overlay-open {
    height: 512px;
}

.chat-overlay-closed {
    height: 78px;
}

.chat-overlay-wrapper {
    width: 376px;
    height: 448px;
}

.chat-overlay-header-mobile {
    display: none;
}

.chat-overlay-header {
    position: fixed;
    bottom: 10px;
    right: 15px;
    height: 56px;
    width: 56px;
    /*border: 1px solid black;*/
    /*background: #000;*/
    margin-left: auto;
    border-radius: 50%;
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.5);
}

#receiver {
    transition: opacity 1s ease-in-out;
    opacity: 1;
    backgroundf: rgba(0, 0, 0, 0.5);
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
}

#receiver.close {
    height: 0;
    opacity: 0;
    overflow: hidden;
}

#receiver.open {
    height: 100%;
    opacity: 1;
    overflow: hidden;
}

.chat-overlay-header-img {
    position: absolute;
    max-width: 54px;
    max-height: 54px;
    transition: opacity 1s ease-in-out;
    right: 0px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
}

.chat-overlay-header-img.open {
    opacity: 0;
}

.absolute-cart-box {
    display: none;
}

@media only screen and (max-width: 768px) {
    .chat-overlay {
        width: 100%;
        position: fixed;
        height: 100%;
    }

    .chat-overlay-header-mobile {
        display: flex;
        width: inherit;
        height: 9%;
        background: #4d5aff;
    }

    .chat-overlay-header-mobile img {
        height: 30%;
        padding: 1rem;
        margin-left: auto;
    }

    .chat-overlay-header-mobile.close {
        display: none;
    }

    #receiver {
        border-radius: 0;
    }

    #receiver.close {
        height: 0;
        opacity: 0;
        overflow: hidden;
    }

    #receiver.open {
        height: 91%;
        opacity: 1;
        overflow: hidden;
    }

    .chat-overlay-open {
        height: 100%;
        bottom: 0px;
        right: 0px;
    }

    .chat-overlay-closed {
        height: 70px;
        bottom: 24px;
        right: 24px;
    }

    .chat-overlay-wrapper {
        width: 100%;
        height: 100%;
    }
}
